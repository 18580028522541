<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Recuperação da Faixa de Areia da Praia Central de Balneário Camboriú',
    titleTemplate: '%s | Prefeitura de BalneáriO Camboriú, SC',
    meta: [

        {
            vmid: 'description',
            name: 'description',
            content: 'Uma nova Praia Central: mais qualidade de vida, mais cuidado com a natureza e mais espaço para você.',
        },
        {
            vmid: 'keywords',
            name: 'keywords',
            content: 'alargamento, praia central, balneário camboriú, bc, recuperação, faixa de areia, recuperação faixa de areia, alargamento faixa de areia, qualidade de vida, natureza',
        },

        ]
    }
}
</script>

<style lang="less">
    @import '@theme/assets/less/base.less';
</style>
