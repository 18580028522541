import Home             from "./pages/home"
import Downloads        from "./pages/downloads"

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home
    },

    {
        name: 'downloads',
        path: '/downloads',
        component: Downloads
    },

  ]

  export default routes