import Vue      from 'vue'
import App      from './App.vue'
import store    from './stores';
import router   from "./router"

Vue.config.productionTip = false

/* SDK */
import sdk from "./sdk"
Vue.use(sdk);

/* Filtros */
require('./filters');


/* VueMeta */
import VueMeta from 'vue-meta'
Vue.use(VueMeta);

/* Vue i18n */
Vue.use(VueI18n)
import VueI18n  from 'vue-i18n';

/* VueCarousel */
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

//carrega idiomas disponíveis
import pt_BR from '../theme/i18n/pt_BR.js';

// SVG
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "vue-svg-inline-plugin/src/polyfills";
Vue.use(VueSvgInlinePlugin);
VueSvgInlinePlugin.install(Vue, {
    attributes: {
        data: [ "src" ],
        remove: [ "alt" ]
    }
});

// Modal
import VModal from 'vue-js-modal'
Vue.use(VModal)

const i18n = new VueI18n({
    locale: 'pt_BR',
    messages: {
        pt_BR
    }
})

//VueSweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);


require("./sections")
require("./components")

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
