import Vue          from 'vue'

import s_cabecalho          from './sections/cabecalho.vue';
import s_rodape             from './sections/rodape.vue';
import s_live               from './sections/live.vue';
import s_boletim            from './sections/boletim.vue';
import s_como_funciona      from './sections/como-funciona.vue';
import s_linha_do_tempo     from './sections/linha-do-tempo.vue';
import s_faq                from './sections/faq.vue'
import s_infografico        from './sections/infografico.vue';
import s_contato            from './sections/contato.vue';

Vue.component('s-cabecalho',            s_cabecalho);
Vue.component('s-rodape',               s_rodape);
Vue.component('s-live',                 s_live);
Vue.component('s-boletim',              s_boletim);
Vue.component('s-como-funciona',        s_como_funciona);
Vue.component('s-linha-do-tempo',       s_linha_do_tempo);
Vue.component('s-faq',                  s_faq);
Vue.component('s-infografico',          s_infografico);
Vue.component('s-contato',              s_contato);