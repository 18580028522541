<template>
    <div>
        <s-cabecalho />

        <section id="downloads">
            <div class="container">

                <div class="cols">
                    <div class="col-1-1">
                        <h1>Downloads</h1>
                    </div>
                </div>

            </div>
            <div class="container">

                <div class="cols">
                    <div class="col-10-12 col-off-1-12">

                        <table id="downloads" style="border-collapse: collapse; width: 100%;" border="1">
                            <thead>
                                <tr>
                                    <td style="width: 25%;">Nome do arquivo</td>
                                    <td style="width: 25%;">Descrição</td>
                                    <td style="width: 25%;">Download</td>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="(arquivo, index) in arquivos" :key="index">
                                    <td style="width: 25%;">{{ arquivo.titulo }}</td>
                                    <td style="width: 25%;">{{ arquivo.descricao }}</td>
                                    <td style="width: 25%;">
                                        <a :href="arquivo.link">Clique aqui para abaixar</a>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
        </section>
        
        <s-rodape />
        
    </div>
</template>
<script>
export default {

    data: function () {
        return {
            arquivos: [
                {
                    titulo: 'Apresentação PEA',
                    descricao: 'Conhecendo a obra e os cuidados ambientais.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=apresentacao-pea.pdf'
                },
                {
                    titulo: 'Folder Nova Praia Central',
                    descricao: 'Etapas da obra e cuidados ambientais.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=folder-da-faixa-de-areia-da-praia-central.pdf'
                },
                {
                    titulo: 'Vídeos Etapas da Obra',
                    descricao: 'Compilado de vídeos com as etapas da obra de alargamento da faixa de areia da praia central.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=videos-do-bira.mp4'
                }
            ]
        }
    }
        
}
</script>