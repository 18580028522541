<template>
    <div :class="'c-tempo-item col-1-2 ' + lado + ' ' + (lado == 'E' ? 'col-off-1-2' : '')">
        
        <div class="box texto" v-if="texto">
            {{ texto }}
        </div>

        <div class="box imagem" v-if="imagem">
            <img :src="imagem" />
        </div>

        <div class="border"></div>

        <div :class="'ano ' + ((!ano)?'vazio':'')">{{ ano }}</div>
        
    </div>
</template>

<script>
export default {
    
    props: {

       ano: {
           type: String,
           required: true,
           default: '999'
       },

       lado: {
           type: String,
           require: true,
           default: 'D'
       },

       texto: {
           type: String,
           require: false
       },

       imagem: {
           type: String,
           require: false
       },

    },
    

}
</script>