<template>
    <section class="linda-do-tempo">
        
        <div class="container">
            <div class="col-off-1-12 col-10-12">
                <h1>Linha do <strong>Tempo</strong></h1>
            </div>
        </div>

        <div :class="{ 'container' : true, 'resumo': resumo }">
            <div class="col-off-1-12 col-10-12">

                <div class="cols">
                    
                    <c-tempo-item v-for="(item, index) in itens" :key="index" :lado="item.Lado" :texto="item.Texto" :ano="item.DataTexto" :imagem="item.Imagem" />

                    <div class="c-tempo-item col-off-1-2 D" style="padding-bottom: 100px;">
                    </div>
                    <!--
                    <div id="ano2021">
                        <div class="ano">2021</div>
                        
                        <div class="c-tempo-item col-1-1">
                            <div class="box imagem" >
                                <img src="https://via.placeholder.com/1000x800" />
                            </div>

                            <div class="border"></div>
                        </div>
                        
                    </div>
                    -->
                    <div class="c-tempo-item col-off-1-2 D" style="margin-top: -30px">
                    </div>

                    <c-tempo-item v-for="(item, index) in itens2021" :key="index" :lado="item.Lado" :texto="item.Texto" :ano="item.DataTexto" :imagem="item.Imagem" />

                </div>
            </div>

            <div class="degrade">
                <button @click="resumo = false">+ ver tudo</button>
            </div>
        </div>
        
    </section>
</template>

<script>
export default {
    data: function () {
        return {
            itens: [],
            itens2021: [],
            resumo: true
        }
    },

     mounted: function(){

        var $this = this;

        $this.$sdk.get('linha-do-tempo', function(data){

            console.log('API - Carregando Linha do Tempo (antes de 2021)...', data);

            var lado = 'D';
            
            data.forEach( function(item) {

                //completa url das imagens...
                if(item.Imagem)
                    item.Imagem = $this.$sdk.prefix_images + item.Imagem;

                item.Lado = lado;

                $this.itens.push(item);

                //muda lado
                if(lado == 'E')
                    lado = 'D';
                else
                    lado = 'E';

            });
        
        })



        $this.$sdk.get('linha-do-tempo?2021=true', function(data){

            console.log('API - Carregando Linha do Tempo depoisde 2021)...', data);

            var lado = 'D';
            
            data.forEach( function(item) {

                //completa url das imagens...
                if(item.Imagem)
                    item.Imagem = $this.$sdk.prefix_images + item.Imagem;

                item.Lado = lado;

                $this.itens2021.push(item);

                //muda lado
                if(lado == 'E')
                    lado = 'D';
                else
                    lado = 'E';

            });
        
        })

    }
    
}
</script>