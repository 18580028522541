<template>
    <section class="boletim">
        
        <div class="container">
            <div class="col-off-1-12 col-10-12">
                <h1>
                    Boletim das <strong>Obras</strong>
                    <a @click="clic_ver_tudo"  v-if="noticias.length > 3">{{ ver_tudo? '+Ver últimas' : '+Ver tudo' }}</a>
                </h1>
            </div>
        </div>


         <div class="container">
            <div class="col-off-1-12 col-10-12">

                <div class="cols">
                    <div class="col-1-3" v-for="(noticia, index) in noticias" :key="index" v-show="(index < 3) || ver_tudo">

                        <div class="box">
                            <c-noticia-lista :noticia="noticia" />
                        </div>
                        
                        
                    </div>
                </div>

            </div>
         </div>


         <div class="container">
            <div class="col-off-1-12 col-10-12">
                <img src="@theme/assets/images/hr.png" class="hr" />
            </div>
         </div>

        
    </section>
</template>

<script>
export default {

    data: function () {
        return {
           ver_tudo: false,
           noticias: []
        }
    },

    methods: {
        clic_ver_tudo: function(){
            
            if(this.ver_tudo){
                this.ver_tudo = false;
            } else {
                this.ver_tudo = true;
            }
            
        },
    },

    mounted: function(){

        var $this = this;

        $this.$sdk.get('boletim', function(data){

            console.log('API - Carregando BOLETIM...', data);
            
            data.forEach( function(noticia) {

                //completa url das imagens...
                noticia.Imagem = $this.$sdk.prefix_images + noticia.Imagem;

                $this.noticias.push(noticia);

            });
            

        })

    }
    
    
}
</script>