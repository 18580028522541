import Vue          from 'vue';

// tudo em maiúsculo
Vue.filter('upper', function (value) {
    return value.toUpperCase();
});

// tudo em minúsculo
Vue.filter('lower', function (value) {
    return value.toLowerCase();
});

//primeira letra do texto em maiúsculo
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
});

// primeira letra de cada palavra em maiúsculo
Vue.filter('name', function (value) {
    if (!value) return '';

    var palavras = value.split(' ');

    palavras.forEach(function(palavra, index){

        //força minúsculo
        palavra = palavra.toLowerCase();

        switch(palavra){
            case 'de':
            case 'para':
            case 'pra':
            case 'por':
            case 'e':
                break;
            
            default:
                palavra = palavra.charAt(0).toUpperCase() + palavra.slice(1);
                break;
        }

        palavras[index] = palavra;
        
    });
     
     return palavras.join(' ');
});

// cep
Vue.filter('cep', function (value) {
    return value.substr(0,2) + '.' + value.substr(2,3) + '-' + value.substr(5,3);
});

// cnpj 
Vue.filter('cnpj', function (value) {
    return value.substr(0,2) + '.' + value.substr(2,3) + '.' + value.substr(5,3) + '/' + value.substr(8,4) + '-' + value.substr(12,2);
});



// data

// exemplo: {{ new Date(xxx) | dateFormat('YYYY.MM.DD') }}
// https://www.npmjs.com/package/vue-filter-date-format
import VueFilterDateFormat from 'vue-filter-date-format';
Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: [
      'Domingo', 'Segunda-feira', 'Terça-feira', 'Quuarta-feira', 'Quinta-feira',
      'Sexta-feira', 'Sábado'
    ],
    dayOfWeekNamesShort: [
      'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'
    ],
    monthNames: [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ],
    monthNamesShort: [
      'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
    ],
    // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
    timezone: 0
  });
