<template>
    <section id="cabecalho">

        <div class="container">
            <div class="cols">
                <div class="col-10-12 col-off-1-12">
                    <router-link :to="{ name: 'home' }">
                        <img src="@theme/assets/images/titulo.png" id="titulo"  />  
                    </router-link>
                </div>
                
            </div>

        </div>
        <div class="container">
            <div class="cols">
                <div class="col-10-12  col-off-1-12">
                    <img :src="progresso" id="barra" />
                </div>
            </div>

            
        </div>
    </section>
</template>

<script>
export default {
    data: function () {
        return {
           progresso: null
        }
    },

     mounted: function(){

         var $this = this;


        this.$sdk.get('progresso', function(data){

            console.log('API - Carregando LBarra de Progresso)...', data);

            $this.progresso = $this.$sdk.prefix_images + data[0].Imagem;
        })

    }
    
}
</script>