<template>
    <div class="c-noticia-lista">
        <a :href="noticia.Link" target="_blank" :title="noticia.Titulo">
            <div class="borda"></div>
            <div class="thumb" :style="{ 'background-image' : 'url(' + noticia.Imagem + ')' }"></div>
            <h3 v-html="noticia.Titulo"></h3>
            <span class="data">{{ new Date(noticia.Data) | dateFormat('DD.MM.YYYY') }}</span>
        </a>
    </div>
</template>
<script>
export default {
    props: {
       noticia: {
           type: Array,
           required: true
       },
    },
    
}
</script>